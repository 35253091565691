<template>
  <div v-if="!token" class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-signin-on"
      id="kt_login"
    >
      <div class="side_image">
        <img src="/media/side_image.png" />
      </div>
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
            >
              <div class="logo text-center mb_85 pt-lg-0 pt-5">
                <img
                  v-if="base_URL.includes('fehm')"
                  style="width: 86%"
                  src="/media/fehm_logo.png"
                />
                <img
                  v-else-if="base_URL.includes('mcit')"
                  alt="Logo"
                  src="/media/logo.png"
                />
                <img v-else alt="Logo" src="/media/Logos/manash.png" />
              </div>
              <div
                v-if="meeting.meeting_status == 'complete'"
                class="error_mesage text-center"
              >
                <p>
                  هذا الإجتماع تم الانتهاء منه بالفعل
                </p>
              </div>
              <div
                v-else-if="meeting.meeting_status == 'pending'"
                class="pending_meeting"
              >
                <h6>الأجتماع سوف يبدأ بعد</h6>
                <div class="counter text-center">
                  <vue-countdown
                    :time="Remainin_time_to_start"
                    v-slot="{ days, hours, minutes, seconds }"
                    class="d-flex justify-content-center"
                  >
                    <div class="d-block time">
                      <strong> {{ days }}</strong> <br />
                      يوم
                    </div>

                    <div class="d-block time">
                      <strong> {{ hours }}</strong> <br />
                      ساعة
                    </div>
                    <div class="d-block time">
                      <strong> {{ minutes }}</strong> <br />
                      دقيقة
                    </div>
                    <div class="d-block time">
                      <strong> {{ seconds }}</strong> <br />
                      ثانية
                    </div>
                  </vue-countdown>
                </div>
              </div>
              <div v-else>
                <div class="form-group">
                  <b-input-group class="mb-2">
                    <b-form-input
                      v-model="guest_name"
                      type="text"
                      placeholder="قم بكتابة إسمك للمتابعة"
                    ></b-form-input>
                  </b-input-group>
                </div>

                <div class="form-group">
                  <v-btn
                    :loading="isLoading ? true : false"
                    ref="kt_login_signin_submit"
                    class="btn btn_brimary font-weight-bolder font-size-h6 px-15 py-4 my-3"
                    @click="guest_meeting()"
                  >
                    الدخول
                  </v-btn>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!--begin::Content footer-->
        <div
          class="login_footer d-flex text-center justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          جميع الحقوق محفوظه لدى وزارة الاتصالات وتقنية المعلومات 2021
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center flex-row-auto"
        :style="{ backgroundImage: `url(/media/Path1.svg` }"
      >
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat position-relative bgi-size-cover bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(/media/tower.png)` }"
        >
          <div class="slogin_container">
            <h3>
              تمكين المملكة العربية السعودية , اقتصاديا<br />
              ومجتمعا ووطنا, من استثمار الفرص التى يوفرها<br />
              عنصر الرقمنه والوصول بالمملكة لطليعة الدول<br />
              المبتكرة
            </h3>
          </div>
          <div class="green_path">
            <img src="/media/Path2.svg" />
          </div>
        </div>
      </div>
      <!--begin::Aside-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import auth from "@/http/requests/auth";
import VueCountdown from "@chenfengyuan/vue-countdown";
export default {
  name: "login-1",
  data() {
    return {
      isLoading: false,
      error_mesage: "",
      email: "",
      password: "",
      state: "signin",
      sucss_mesage: "",
      token: null,
      base_URL: "",
      lastUrl: "",
      meeting: {},
      guest_name: "",
      Remainin_time_to_start: 0,
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),
  },
  mounted() {},
  components: {
    VueCountdown,
  },
  methods: {
    check_meeting_status() {
      this.$vs.loading();
      auth
        .check_meeting_status({ slug: this.$route.params.meeting_id })
        .then((res) => {
          this.meeting = res.data.data;
          var start = res.data.data.meeting_start_time;
          this.Remainin_time_to_start = new Date(start) - new Date();
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response.data.error) {
            this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
          } else {
            this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
          }
        });
    },
    guest_meeting() {
      this.$vs.loading();
      auth
        .guest_meeting({
          slug: this.$route.params.meeting_id,
          name: this.guest_name,
        })
        .then((res) => {
          window.localStorage.setItem("gust_name", this.guest_name);
          window.localStorage.setItem("gust_token", res.data.token);
          window.location.href = `#/mt/${this.$route.params.meeting_id}`;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response.data.error) {
            this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
          } else {
            this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
          }
        });
    },
  },
  beforeRouteUpdate(to, from, next) {
    next(this.check_meeting_status());
  },
  created() {
    this.lastUrl = sessionStorage.getItem("lastUrl");
    this.base_URL = window.location.origin;
    this.check_meeting_status();
  },
};
</script>
<style scoped lang="scss">
.form-control {
  background: transparent !important;
}
</style>
