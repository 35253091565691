import axios from 'axios'
import baseURL from '../../axios'
axios.create({
  baseURL,
//   headers: {
//     'Content-Type': 'application/json'
//   }
})
export default {
    check_valid_group(data) {
        return axios.post(`/check_valid_group`,data)
    },
    user_check_join_group(data) {
        return axios.post(`/user_check_join_group`,data)
    },
    Register(data) {
        return axios.post(`/register`,data)
    },
    update_user_info(data) {
        return axios.post(`/update_user_info`,data)
    },
    verfiy_mobile(data) {
        return axios.put(`/verfiy_mobile`,data)
    },
    Update_mobile(data) {
        return axios.put(`/update_user_mobile`,data)
    },
    Update_email(data) {
        return axios.put(`/update_email`,data)
    },
    replay_sms_mobile(data) {
        return axios.post(`/replay_sms_mobile`,data)
    },
    replay_email(data) {
        return axios.put(`/send_email_activation`,data)
    },
    verfiy_email(data) {
        return axios.put(`/verfiy_email`,data)
    },
    easy_user_login(data) {
        return axios.post(`/easy_user_login`,data)
    },
    verfiy_log_code(data) {
        return axios.post(`/verfiy_log_code`,data)
    },
    check_meeting_status(data) {
        return axios.post(`/check_meeting_status`,data)
    },
    guest_meeting(data) {
        return axios.post(`/guest_meeting`,data)
    },
}

